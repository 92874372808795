import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { ULPFormData } from "./models/ULPForm";
import {
	Button,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	CircularProgress,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { panRegx, pincodeRegx } from "../../../utils/commonRegExs";
import { toUpper } from "lodash";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayjsFnsUtils from "@date-io/dayjs";
import { RLPFormData } from "./models/RLPForm";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import {
	actions,
	fetchPanDetails,
	fetchPincodeDetails,
	updateApplicant,
} from "../../../store/application/actions";
import useApplication from "../../../hooks/applicationHook";
import { RootState } from "../../../config/store";
import { checkForErrors, checkForLoading } from "../../../utils/helpers";
import { ApplicantMapper } from "./ApplicantMapper";
import { removeErrors } from "../../../store/ui/actions";
import { Autocomplete } from "@material-ui/lab";
// import { checkStatus, checkResponseCode } from "../../../utils/apiUtils";
import {StateList} from "./models/StateList";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			padding: 16,
		},
		margin: {
			marginBottom: theme.spacing(3),
		},
	})
);

const mapStateToProps = (state: RootState) => {
	return {
		panLoader: checkForLoading(
			state.ui.spinners,
			actions.FETCH_PAN_DETAILS
		),
		pincodeLoader: checkForLoading(
			state.ui.spinners,
			actions.FETCH_PINCODE_DETAILS
		),
		updateApplicantLoader: checkForLoading(
			state.ui.spinners,
			actions.UPDATE_APPLICANT
		),
        isThereErrorOnPanFetch: checkForErrors(
			state.ui.errors,
			actions.FETCH_PAN_DETAILS
		),
        ui: state.ui,
	};
};

const mapDispatchToProps = {
	fetchPanDetails,
	fetchPincodeDetails,
	updateApplicant,
    removeErrors
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

type ApplicantUlpFormProps = {
	defaults: ULPFormData;
};

const ApplicantUlpForm: React.FC<ApplicantUlpFormProps & ReduxProps> = (
	props
) => {
	const { application, panDetails, pincodeDetails } = useApplication();
	const [panError, setPanError] = useState(undefined);
	const { handleSubmit, watch, setValue, errors, control, setError } =
		useForm<ULPFormData>({
			mode: "onChange",
			defaultValues: props.defaults,
		});

	const onSubmit = handleSubmit((data: ULPFormData) => {
		const mappedReq = ApplicantMapper.MapUlp(
			data,
			application?.CreationReference!
		);

		props.updateApplicant(mappedReq);
	});

	const classes = useStyles();
	const showPanLoader = props.panLoader;
	const showPincodeLoader = props.pincodeLoader;

	useEffect(() => {
		if (panDetails) {
			setValue("applicantName", panDetails?.PanDetails.Name);
			setValue("fathersName", panDetails?.PanDetails.FatherName);
		}
	}, [panDetails]);

	useEffect(() => {
		if (pincodeDetails) {
			setValue("currentAddressCity", pincodeDetails?.Details.City);
			setValue("currentAddressState", pincodeDetails?.Details.State.toUpperCase());
		}
	}, [pincodeDetails]);

    useEffect(() => {
		if (props.isThereErrorOnPanFetch) {
			const err = props.ui.errors[actions.FETCH_PAN_DETAILS][0];
			const errorMessage = err.description || "Invalid PAN number!";
			if (err.errType == "field") {
				setValue("applicantName", "");
				setValue("fathersName", "");
				setError("pan", {
					message: errorMessage,
					types: {
						required: err.description,
					},
					type: "required",
				});
				setPanError(errorMessage);
			}
			props.removeErrors([actions.FETCH_PAN_DETAILS]);
		}
	}, [props.isThereErrorOnPanFetch]);

	const onPanUpdate = (value: string) => {
		if (/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(value) !== true) {
			return;
		}
		if (value.length === 10) {
			props.fetchPanDetails({
				IsCoBorrower: false,
				ApplicationCreationReference: application?.CreationReference!,
				PanNumber: value,
			});
		}
	};

	const onPincodeUpdate = (value: string) => {
		if (!value) return;
		if (value.length !== 6) return;

		props.fetchPincodeDetails({
			ApplicationCreationReference: application?.CreationReference!,
			IsCoBorrower: false,
			Pincode: value,
		});
	};

	return (
		<form className={classes.root} onSubmit={onSubmit}>
			<Controller
				name="pan"
				data-test-id="applicant-details__pan"
				render={(props) => {
					return (
						<TextField
							className={classes.margin}
							variant="outlined"
							label="PAN"
							data-test-id="applicant-details__pan"
							value={props.value}
							fullWidth
							error={!!errors.pan}
							helperText={errors.pan?.message}
							onChange={(e) => {
                                setPanError(undefined)
								onPanUpdate(toUpper(e.target.value));
								props.onChange(toUpper(e.target.value));
							}}
							InputProps={{
								endAdornment: showPanLoader ? (
									<CircularProgress size="1.5rem" />
								) : (
									""
								),
							}}
						/>
					);
				}}
				rules={{
					required: { value: true, message: "Required Field!" },
					pattern: { value: panRegx, message: "Invalid PAN number!" },
                    validate: (val) => {
						if (!panDetails) {
                            return panError;
						}
					},
				}}
				control={control}
			/>
			<Controller
				as={TextField}
				fullWidth
				name="applicantName"
				data-test-id="applicant-details__name"
				className={classes.margin}
				variant="outlined"
				label="Full Name"
				error={!!errors.applicantName}
				helperText={errors.applicantName?.message}
				rules={{
					required: { value: true, message: "Name is required!" },
				}}
				control={control}
			/>
			<Controller
				as={TextField}
				fullWidth
				name="fathersName"
				data-test-id="applicant-details__father-name"
				className={classes.margin}
				variant="outlined"
				label="Father's Name"
				error={!!errors.fathersName}
				helperText={errors.fathersName?.message}
				rules={{
					required: { value: true, message: "Father's is required!" },
				}}
				control={control}
			/>

			<Controller
				name="dateOfBirth"
				control={control}
				data-test-id="applicant-details__date-of-birth"
				defaultValue={null}
				rules={{
					required: {
						value: true,
						message: "Date Of Birth Required!",
					},
				}}
				render={(props) => {
					return (
						<MuiPickersUtilsProvider utils={DayjsFnsUtils}>
							<DatePicker
								disableFuture
								fullWidth
								error={!!errors.dateOfBirth}
								data-test-id="applicant-details__dob-date-picker"
								helperText={errors.dateOfBirth?.message}
								openTo="year"
								className={classes.margin}
								inputVariant="outlined"
								format="DD/MM/YYYY"
								views={["year", "month", "date"]}
								placeholder="DD/MM/YYYY"
								label="Date Of Birth"
								value={props.value}
								onChange={(date) => {
									props.onChange(date?.format("YYYY-MM-DD"));
								}}
							/>
						</MuiPickersUtilsProvider>
					);
				}}
			/>
			<Controller
				name="gender"
				rules={{
					required: {
						value: true,
						message: "Gender is required!",
					},
				}}
				render={(props) => {
					return (
						<FormControl
							error={!!errors.gender}
							fullWidth
							variant="outlined"
							className={classes.margin}
							data-test-id="applicant-details__gender"
						>
							<InputLabel>Gender</InputLabel>
							<Select
								value={props.value}
								onChange={(e) => {
									props.onChange(e);
								}}
								label="Gender"
							>
								<MenuItem value={"male"}>Male</MenuItem>
								<MenuItem value={"female"}>Female</MenuItem>
								<MenuItem value={"other"}>Other</MenuItem>
							</Select>
							{!!errors.gender && (
								<FormHelperText data-test-id="applicant-details__gender-selection-error">
									{errors.gender.message}
								</FormHelperText>
							)}
						</FormControl>
					);
				}}
				control={control}
			/>

			<Controller
				name="currentAddressPincode"
				render={(props) => {
					return (
						<TextField
							className={classes.margin}
							variant="outlined"
							label="Current Address Pincode"
							data-test-id="applicant-details__current-address-pincode"
							value={props.value}
							fullWidth
							inputProps={{
								maxLength: 6,
							}}
							error={!!errors.currentAddressPincode}
							helperText={errors.currentAddressPincode?.message}
							onChange={(e) => {
								onPincodeUpdate(toUpper(e.target.value));
								props.onChange(toUpper(e.target.value));
							}}
							InputProps={{
								endAdornment: showPincodeLoader ? (
									<CircularProgress size="1.5rem" />
								) : (
									""
								),
							}}
						/>
					);
				}}
				rules={{
					required: { value: true, message: "Required Field!" },
					pattern: {
						value: pincodeRegx,
						message: "Invalid Pincode!",
					},
				}}
				control={control}
			/>

			<Controller
				as={TextField}
				fullWidth
				name="currentAddressLine1"
				data-test-id="applicant-details__current-address-line-1"
				className={classes.margin}
				variant="outlined"
				label="Current Address (House/Flat No)"
				error={!!errors.currentAddressLine1}
				helperText={errors.currentAddressLine1?.message}
				rules={{
					required: {
						value: true,
						message: "House/Flat No is required!",
					},
				}}
				control={control}
			/>

			<Controller
				as={TextField}
				fullWidth
				name="currentAddressLine2"
				data-test-id="applicant-details__current-address-line-2"
				className={classes.margin}
				variant="outlined"
				label="Current Address Line 1"
				error={!!errors.currentAddressLine2}
				helperText={errors.currentAddressLine2?.message}
				rules={{
					required: {
						value: true,
						message: "Address Line 1 is required!",
					},
				}}
				control={control}
			/>

			<Controller
				as={TextField}
				fullWidth
				name="currentAddressCity"
				data-test-id="applicant-details__current-address-city"
				className={classes.margin}
				variant="outlined"
				label="Current Address City"
				error={!!errors.currentAddressCity}
				helperText={errors.currentAddressCity?.message}
				rules={{
					required: {
						value: true,
						message: "City is required!",
					},
				}}
				control={control}
			/>

			{/* <Controller
				as={TextField}
				fullWidth
				name="currentAddressState"
				data-test-id="applicant-details__current-address-state"
				className={classes.margin}
				variant="outlined"
				label="Current Address State"
				error={!!errors.currentAddressState}
				helperText={errors.currentAddressState?.message}
				rules={{
					required: {
						value: true,
						message: "State is required!",
					},
				}}
				control={control}
			/> */}
			<Controller
				fullWidth
				name="currentAddressState"
				control={control}
				error={!!errors.currentAddressState}
				helperText={errors.currentAddressState?.message}
				rules={{
					required: {
						value: true,
						message: "State is required!",
					},
				}}
				render={(props)=>{
					return(
						<Autocomplete
							options={StateList}
							value={props.value}
							onChange={(e:any, newValue:any)=>{
								props.onChange(newValue);
							}}
							getOptionLabel={(option:any)=>{
								if(option.label)
								{
									return(option.label)
								}
								else
								{
									return(option)
								}
							}}
							renderInput={(params:any) => (
								<TextField
								{...params}
									className={classes.margin}
									data-test-id="applicant-details__current-address-state"
									label="Current Address State"
									variant="outlined"
								/>
							)}
						/>
					)
				}}
			/>

			<Button
				startIcon={
					props.updateApplicantLoader ? (
						<CircularProgress size="1rem" />
					) : undefined
				}
				disabled={props.updateApplicantLoader}
				variant="contained"
				color="primary"
				data-test-id="applicant-details__save-btn"
				onClick={onSubmit}
			>
				Save Details
			</Button>
		</form>
	);
};

export default connector(ApplicantUlpForm);
